

const curiosities = [
    'As baratas existem há 120 milhões de anos, antes mesmo dos dinossauros vagarem pela Terra.',
    'As abelhas podem picar outras abelhas, geralmente se se sentirem ameaçadas ou se estiverem protegendo seu território.',
    'Quando criança, Adolf Hitler queria ser padre. Ele também sofria de Ailurofobia, que é o medo de gatos.',
    'Uma cervejaria no Canadá fabrica cerveja usando água de icebergs de 20.000 anos.',
    'A qualquer momento, há aproximadamente 2.000 tempestades acontecendo na Terra.',
    'Como os humanos, os coalas possuem impressões digitais individuais únicas.',
    'Em média, uma pessoa anda o equivalente a três vezes ao redor do mundo na vida.',
    'O núcleo da Terra é tão quente quanto a superfície do Sol.',
    'A Rota 66 é mais longa que a distância até o centro da Terra.',
    'A Islândia cresce 5 centímetros por ano por causa de suas placas tectônicas em movimento.',
    'O deserto do Saara pode atingir até 136 graus.',
    'Um milhão de terras caberiam dentro do sol.',
    'Os arrepios que você sente ao ouvir música são causados ​​pelo fato de seu cérebro liberar dopamina.',
    'A Austrália é mais larga que a lua.',
    'O casamento do príncipe Charles com a falecida princesa Diana atraiu quase 750 milhões de telespectadores em todo o mundo.',
    'Um terremoto de magnitude 12 dividiria a Terra ao meio.',
    'A maior abóbora do mundo pesa mais do que um carro esportivo.',
    'O coração de um camarão está localizado em sua cabeça.',
    'A Terra não é exatamente redonda. A rotação e a gravidade causaram um leve achatamento. Essa forma é chamada geoide.',
    'Cabo de guerra costumava ser um esporte olímpico.',
    'A Finlândia é o país mais feliz do mundo , de acordo com o Relatório de Felicidade Mundial de 2019.',
    'Os grãos de baunilha são produto da única orquídea frutífera do mundo, a Vanilla planifolia.',
    'Mamutes lanosos ainda estavam vivos quando as pirâmides foram construídas.',
    'Antigamente, as cenouras eram roxas e não laranjas.',
    'A "Ilha das Cobras" ou Ilha da Queimada Grande fica em São Paulo, no Brasil e tem 4.000 cobras. Portanto, é uma das ilhas mais mortíferas do mundo.',
    'Há um bilhão de micróbios em uma colher de chá de solo.',
    'Homens têm 6 vezes mais probabilidade de serem atingidos por raios do que mulheres.',
    'Você não pode roncar e sonhar ao mesmo tempo.',
    'É fisicamente impossível para os porcos olharem para o céu.',
    'Em média, uma pessoa passa 6 meses de sua vida parada no semáforo, esperando a luz vermelha ficar verde.',
    'Picasso era um suspeito no roubo da Mona Lisa em 1911.',
    'Lethologica é o termo usado para quando você não consegue se lembrar de uma palavra.',
    'Um em cada cinco adultos acredita que alienígenas estão se escondendo em nosso planeta disfarçados de humanos.',
    'Todos os peixes-palhaço nascem machos.',
    'A Finlândia tem mais saunas do que carros.',
    'O Gatorade foi inventado na Universidade da Flórida, em homenagem ao mascote da escola, os Gators.',
    'O voleibol e o basquete foram inventados em Massachusetts.',
    'Ao contrário do que você possa imaginar, o Canadá come mais macarrão com queijo do que qualquer outro lugar.',
    'Existem mais de 70 variedades de cogumelos que brilham no escuro.',
    'Os gatos exibem os mesmos principais estilos de apego que os bebês e cachorros, segundo um estudo.',
    'As pessoas desenvolvem primeiras impressões sobre você em um décimo de segundo.',
    'A Terra está repleta de vírus. Existem cerca de dez nonilhões de vírus (10 elevado a 31) no planeta, e isso é mais que as estrelas do universo.',
    'Pessoas que comem chocolate amargo têm menos probabilidade de ficar deprimidas, descobriu um grande estudo.',
    'Os mirtilos não amadurecem até serem colhidos.',
    'O ketchup era usado como medicamento na década de 1930.',
    '​​O mel nunca estraga.',
    'Cada carro de polícia holandês tem um ursinho de pelúcia.',
    'Um ovo cozido gira, mas um ovo cozido não.',
    'Os abacates são venenosos para os pássaros.',
    'A goma de mascar queima cerca de 11 calorias por hora.',
    'As bananas são curvas devido à forma como crescem em direção ao sol.',
    'Se você tentar suprimir um espirro, poderá romper um vaso sanguíneo na sua cabeça ou pescoço e morrer.',
    'O aipo tem calorias negativas! É preciso mais calorias para comer um pedaço de aipo do que o aipo contém. É o mesmo com as maçãs!',
    'Mais pessoas são alérgicas ao leite de vaca do que a qualquer outro alimento.',
    'Apenas 8% das pessoas que fazem dieta seguirão um plano restritivo de perda de peso (como dieta marroquina, dieta com ovos, etc.)',
    'A água de coco pode ser usada como plasma sanguíneo.',
    'O algodão doce foi inventado por um dentista.',
    'Os ossos da coxa humanos são mais fortes do que concreto.',
    'As baratas podem viver várias semanas com a cabeça decepada, porque o cérebro está localizado dentro do corpo. No entanto, elas acabam morrendo por não conseguirem comer.',
    'O olfato dos cães é 40 vezes mais poderoso do que o dos humanos.',
    'Para produzir 450 g de mel, uma única abelha teria que visitar 2 milhões de flores.',
    'Espera-se que a população aumente para 10,8 bilhões no ano 2080.',
    'Você respira em média cerca de 8.409.600 vezes por ano',
    'Um homem da Grã-Bretanha mudou seu nome para Tim Pppppppppprice para dificultar a pronúncia dos operadores de telemarketing.',
    'A Apple lançou uma linha de roupas em 1986. Ela foi descrita como um “desastre” por algumas pessoas.',
    'Durante a sua vida, você produzirá saliva suficiente para encher duas piscinas.',
    'No Japão, dentes tortos são considerados bonitos e atraentes.',
    'Se 33 milhões de pessoas dessem as mãos, elas poderiam fazer todo o caminho ao redor do Equador.',
    'Os quadrinhos do pato Donald foram banidos da Finlândia porque ele não usa calças.',
    'A chance de você morrer no caminho para conseguir bilhetes de loteria é na verdade maior do que a sua chance de ganhar.',
    'A Terra é o único planeta que não tem o nome de um deus da mitologia.',
    'Você nasceu com 300 ossos, mas quando for adulto terá apenas 206.',
    'Assim como as impressões digitais, todo mundo tem diferentes impressões na língua.',
    'Um “instante” é o nome científico para 1/100 de segundo.',
    'Um quarto dos ossos do seu corpo está localizado nos pés.',
    's fatos aleatórios',
    'Pessoas de olhos azuis tendem a ter a maior tolerância ao álcool.',
    'O maior congestionamento já registrado durou 12 dias.',
    'A língua é o músculo mais forte do corpo.',
    'Todos os anos, mais de 2500 canhotos são mortos pelo uso de produtos destros.',
    'Mais de 50% das pessoas no mundo nunca fizeram ou receberam uma chamada telefônica.',
    'A Escócia tem 421 palavras diferentes para neve.',
    'As borboletas provam sua comida com os pés.',
    'Uma tarântula pode viver sem comida por mais de dois anos.',
    'A língua de uma baleia azul pesa mais do que a maioria dos elefantes.',
    'Cachorros machos levantam as pernas quando estão urinando por um motivo. Eles estão tentando deixar sua marca mais alta, para transmitir a mensagem de que são altos e intimidadores.',
    'Um colibri pesa menos de um centavo.',
    'O olho de um avestruz é maior que seu cérebro',
    'Os cães são capazes de compreender até 250 palavras e gestos e demonstraram a habilidade de fazer cálculos matemáticos simples.',
    'Uma ovelha, um pato e um galo foram os primeiros passageiros em um balão de ar quente.',
    'Pássaros não urinam.',
    'Uma pulga pode pular até 200 vezes sua altura.',
    'Existem 5 templos em Kyoto, Japão, com tetos manchados de sangue. Os tetos são feitos de tábuas do assoalho de um castelo onde guerreiros se mataram após uma longa resistência contra um exército. Até hoje, você ainda pode ver os contornos e pegadas.',
    'Existe uma serpente, chamada boomslang, cujo veneno faz com que você sangre por todos os orifícios do corpo. Você pode até ficar azul de hemorragia interna e pode levar até 5 dias para morrer por causa do sangramento.',
    'Uma bola de vidro vai quicar mais alto do que uma bola de borracha.',
    'A densidade de Saturno é baixa o suficiente para que o planeta flutue na água.',
    '68% do universo é energia escura e 27% é matéria escura; ambos são invisíveis, mesmo com nossos poderosos telescópios. Isso significa que vimos apenas 5% do universo da terra.',
    'Nos últimos 20 anos, os cientistas encontraram mais de 1.000 planetas fora de nosso sistema solar.',
    'A Netflix é responsável por 15% do tráfego global da Internet.',
    'Quando Steve Jobs estava em seu leito de morte , ele pediu cinco máscaras de oxigênio diferentes para poder escolher aquela com o melhor design.',
    'Com o aumento da temperatura global, as flores estão emitindo menos perfume.'
]

const getCuriosity = () => curiosities[Math.floor(Math.random() * curiosities.length)]

export default getCuriosity