import { Link, useNavigate } from 'react-router-dom'
import '../../../assets/scss/components/post.scss'
import { BsThreeDots, BsHandThumbsUpFill } from "react-icons/bs"
import { getGender, getUser } from '../../../utility/Utils'
import api from '../../../services/api'
import { toast } from 'react-toastify'
import { useState } from 'react'


const generateLabelHeader = (user) => {
    let label = '', data = 0
    const separator = ' • '
    const maxInfoInLabel = 3

    if (user.info.gender) {
        label += getGender(user.info.gender)
        data++
    }

    if (user.info.pronouns) {
        label += separator + user.info.pronouns
        data++
    }

    if (user.info.job) {
        label += separator + user.info.job
        data++
    }

    if (user.info.workplace && data < maxInfoInLabel) {
        label += separator + user.info.workplace
        data++
    }

    if (user.info.city && data < maxInfoInLabel) {
        label += separator + user.info.city

        if (user.info.state) {
            label += separator + user.info.state
        }
    }

    return label
}

const Post = ({post, showAll, removePostFromList}) => {
    const user = getUser()
    const navigate = useNavigate()
    let canDelete = user.id === post.user_id

    const [liked, setLiked] = useState(post.likes.find(e => e.user_id === user.id))
    const [commentInput, setCommentInput] = useState('')

    const [likes, setLikes] = useState(post.likes)
    const [comments, setComments] = useState(post.comments)

    const deleteAction = async () => {
        if (!canDelete) {
            return
        }

        canDelete = false

        try {
            const { data } = await api.delete(`post/${post.id}`)

            if (data.data.result === true) {
                if (removePostFromList) {
                    removePostFromList(post.id)
                } else {
                    navigate('/app')
                }
            } else {
                toast.error('Não foi possivel deletar o post')   
            }
        } catch (error) {
            console.log(error)
            toast.error('Não foi possivel deletar o post')   
        }
    }

    const toggleLikeAction = async () => {
        try {
            if (!liked) {
                const { data } = await api.post(`like`, {post_id: post.id})
    
                if (data.status === true) {
                    setLiked(data.data.like)
                    setLikes([data.data.like, ...likes])
                    console.log([data.data.like, ...likes], data.data.like.id)
                }
            } else {
                const { data } = await api.delete(`like/${liked.id}`, {post_id: post.id})
    
                if (data.status === true) {
                    console.log(likes, liked.id)
                    setLikes(likes.filter(e => e.id !== liked.id))
                    setLiked(null)
                }
            }
        } catch (error) {
            console.log(error)
            toast.error('Não foi possivel curtir o post')   
        }
    }

    const publishCommentAction = async () => {
        try {
            const { data } = await api.post(`comment`, {
                post_id: post.id,
                content: commentInput
            })

            if (data.status === true) {
                setComments([data.data.comment, ...comments])
                setCommentInput('')
            } else {
                toast.error('Não foi possivel comentar no post')   
            }
        } catch (error) {
            console.log(error)
            toast.error('Não foi possivel comentar no post')   
        }
    }

    return (
        <section className='post-component'>
            <div className='header-container'>
                <div className='img-container'>
                    <Link to={`/app/profile/${post.user.id}`}>
                        <img src={process.env.REACT_APP_MEDIA_URL + post.user.picture} />
                    </Link>
                </div>
                <div className='info-container'>
                    <Link to={`/app/profile/${post.user.id}`}>
                        <p className='h6 m-0'>{post.user.name}</p>
                    </Link>

                    <p className='text-muted small'>
                        @{post.user.username} • {generateLabelHeader(post.user)}
                    </p>
                </div>
                <div className='options-container'>
                    <BsThreeDots className="options-btn" />
                    <div className="dropdown-content">
                        {user.id === post.user_id && (
                            <>
                                <p onClick={deleteAction}>Deletar</p>
                            </>
                        )}

                        {user.id !== post.user_id && (
                            <>
                                <p>Denunciar</p>
                                <Link to={`/chat/${post.user_id}`}>Conversar no chat</Link>
                            </>
                        )}
                    </div>
                </div>
            </div>

            <div className='content-container'>
                <div className='text'>
                    {post.content}
                </div>

                <section className='medias-container'>
                    {post.medias.map(e => (
                        <div key={e.id} className='media'>
                            <img src={process.env.REACT_APP_MEDIA_URL + e.media_url} />
                        </div>
                    ))}
                </section>
            </div>

            <div className='statics-container'>
                <Link to={`/app/post/${post.id}`}>
                    <p>{likes.length} curtida(s) • {comments.length} comentário(s)</p>
                </Link>

                <BsHandThumbsUpFill
                    className={liked ? 'like-button liked' : 'like-button'}
                    onClick={toggleLikeAction}
                />
            </div>
            
            {showAll && (
                <>
                    <div className='create-comment-container'>
                        <img src={process.env.REACT_APP_MEDIA_URL + post.user.picture} className='profile-picture' />
                        <textarea className='create-comment-textarea' placeholder='Publique sua ideia sobre o post' value={commentInput} onChange={e => setCommentInput(e.target.value)}></textarea>
                        <button className='publish-button' onClick={publishCommentAction}>Publicar</button>
                    </div>

                    <div className='comment-list-container'>
                        {comments.map((e) => (
                            <div key={e.id} className='comment'>
                                <img src={process.env.REACT_APP_MEDIA_URL + e.user.picture} className='profile-picture' />
                                <section>
                                    <p className='info'><b>@{e.user.username}</b> • {generateLabelHeader(e.user)}</p>
                                    <p className='comment-content'>
                                        {e.content}
                                    </p>
                                </section>
                            </div>
                        ))}
                    </div>
                </>
            )}
        </section>
    )
}

export default Post